import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
// import {handle_variables} from '../Redux/flowerSlice'
// import {handle_variables as main_handle_variables} from '../../../redux/mainSlice'
// import FlowerDefinesModal from './flowerDefineModal'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'

import useSelection from 'antd/es/table/hooks/useSelection'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {toast} from 'react-toastify'
import {
  getAllSaleHistoryDashborad,
  getSellManagements,
  handle_variables,
  sellState,
} from '../../redux/sellSlice'
import PriceModal from './PriceModal'
import GradeModal from './GradeModal'
import {Link, useHistory} from 'react-router-dom'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {getGrades, gradeState} from '../../../grade/redux/gradeSlice'
import ChangeFlowersPriceModal from './ChangeFlowersPriceModal'
import {authState} from '../../../auth/redux/AuthSlice'
import {priceSlice} from '../../../../functions/priceSlice'
import {flowerState, getOrganizationFlower} from '../../../flower/redux/flowerSlice'
import DefaultDatePicker from '../../../../components/DefaultDatePicker'
import {cooperationState, getCoopRequest} from '../../../partners/Redux/cooperationSlice'
import {convertDate} from '../../../../functions/convertDate'

const SaleHistoryDashborad = () => {
  const dispatch = useDispatch()
  const state = useSelector(sellState)
  const flowerS = useSelector(flowerState)
  const authS = useSelector(authState)
  const coopS = useSelector(cooperationState)
  const gradeS = useSelector(gradeState)
  const history = useHistory()
  const [editMode, setEditMode] = useState(false)
  const [deleteComponentShow, setDeleteComponentShow] = useState(false)
  const [defaultValue, setDefaultValue] = useState(null)
  const [checkboxValues, setCheckboxValues] = useState<any[]>([])
  const [flowerTitleFilter, setFlowerTitleFilters] = useState('')
  const [gradeTitleFilter, setGradeTitleFilter] = useState('')
  const [filterDate, setFilterDate] = useState({from: '', to: ''})
  const [coops, setCoops] = useState<any[]>([])
  const [flowerIdsFilter, setFlowerIdsFilter] = useState<any[]>([])
  const [toOrganizationIdsFilter, setToOrganizationIdsFilter] = useState<any[]>([])
  const [buyerUserIdsFilter, setBuyerUserIdsFilter] = useState<any[]>([])

  const data = state.saleAllHistoryDashborad

  const saleHistory = data?.saleHistories?.length > 0 ? data.saleHistories : []

  const organization = () => {
    if (coopS?.coops.length > 0) {
      const filteredData: any = coopS?.coops.filter((item: any) => item.cooperationStatusId == 1)

      const data: any = []

      if (filteredData.length > 0) {
        filteredData.map((x: any) => {
          if (authS.user.organizationId != x.fromOrganization.id) {
            data.push(x.fromOrganization)
          } else {
            data.push(x.toOrganization)
          }
        })
        return data
      }

      return false
    }
  }

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getAllSaleHistoryDashborad(data))
  }, [])

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
      organizationId: authS.user.organizationId,
    }
    dispatch(getOrganizationFlower(data))

    const datacoop = {
      page: 1,
      pageSize: 50,
    }
    dispatch(getCoopRequest(datacoop))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        saleAllHistoryDashboradPagination: {
          ...state.saleAllHistoryDashboradPagination,
          page: currentPage,
        },
      })
    )
    dispatch(
      getAllSaleHistoryDashborad({
        page: currentPage,
        pageSize: state.saleAllHistoryDashboradPagination.limit,
      })
    )
  }
  return (
    <>
      {state.changePriceModalOpen ? (
        <PriceModal defaultValue={defaultValue} edit={editMode} />
      ) : null}

      {state.changeFlowersPriceModalOpen ? <ChangeFlowersPriceModal ids={checkboxValues} /> : null}

      {state.changeGradeModalOpen ? (
        <GradeModal defaultValue={defaultValue} edit={editMode} />
      ) : null}
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <Row className='w-100'>
            <Col lg={2}>
              <h3 className='card-title align-items-center'>
                <span className='card-label fw-bolder fs-3 mb-1'>گزارش فروش</span>
              </h3>
            </Col>
          </Row>
          <Row className='w-100 mt-5 mb-8'>
            <Col lg={3}>
              <DefaultDropDown
                name='flower'
                onChange={(e: any) => {
                  if (e.length > 0) {
                    const dataArr: any = []
                    e.map((x: any) => {
                      dataArr.push(x.id)
                    })
                    setFlowerIdsFilter(dataArr)
                  } else {
                    setFlowerIdsFilter([])
                  }
                }}
                type='default'
                isMulti={true}
                placeholder='گل'
                options={flowerS.organizationFlowers}
              />
            </Col>
            <Col lg={3}>
              <DefaultDropDown
                name='toOrganization'
                onChange={(e: any) => {
                  if (e.length > 0) {
                    const dataArr: any = []
                    e.map((x: any) => {
                      dataArr.push(x.id)
                    })
                    setToOrganizationIdsFilter(dataArr)
                  } else {
                    setToOrganizationIdsFilter([])
                  }
                }}
                type='default'
                isMulti={true}
                placeholder='غرفه'
                options={organization()}
              />
            </Col>
          </Row>
          <Row className='w-100'>
            <Col lg={4}>
              <DefaultDatePicker
                from={(e: string) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    from: e,
                  }))
                }
                to={(e: any) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    to: e,
                  }))
                }
                type='double'
              />
            </Col>
            <Col lg={1} className='mt-5 mt-lg-0'>
              <Button
                onClick={() => {
                  const data = {
                    page: state?.saleAllHistoryDashboradPagination?.page,
                    pageSize: state?.saleAllHistoryDashboradPagination?.limit,
                    fromDate: filterDate.from,
                    toDate: filterDate.to,
                    toOrganizationId:
                      toOrganizationIdsFilter.length > 0
                        ? JSON.stringify(toOrganizationIdsFilter)
                        : [],
                    flowerId: flowerIdsFilter.length > 0 ? JSON.stringify(flowerIdsFilter) : [],
                  }

                  dispatch(getAllSaleHistoryDashborad(data))
                }}
              >
                جستجو
              </Button>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 mt-6'>
          {/* begin::Table container */}
          {state.saleAllHistoryDashboradTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <>
              <div className='table-responsive'>
                {/* begin::Table */}
                {saleHistory < 1 ? (
                  'موردی یافت نشد'
                ) : (
                  <>
                    <Row className='my-5 d-flex justify-content-center'>
                      <Col md={3}>
                        <div
                          className='financ-card d-flex flex-column justify-content-evenly align-items-center'
                          style={{backgroundColor: '#ffcc80'}}
                        >
                          <span className='fs-2 fw-bold'>تعداد گل فروخته شده </span>
                          <span className='fs-3 fw-bold'>{data?.totalAmount}</span>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div
                          className='financ-card d-flex flex-column justify-content-evenly align-items-center'
                          style={{backgroundColor: '#a5d6a7'}}
                        >
                          <span className='fs-2 fw-bold'>مجموع مبلغ فروش</span>
                          <span className='fs-3 fw-bold'>
                            {priceSlice({data: +data?.totalFinalPrice})}
                          </span>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div
                          className='financ-card d-flex flex-column justify-content-evenly align-items-center'
                          style={{backgroundColor: '#ffab91'}}
                        >
                          <span className='fs-2 fw-bold'>میانگین مبلغ فروش</span>
                          <span className='fs-3 fw-bold'>
                            {priceSlice({data: +data?.averagePrice})}
                          </span>
                        </div>
                      </Col>
                    </Row>

                    <table className='table table-row-bordered mt-5 table-row-gray-100 align-middle gs-0 gy-3'>
                      {/* begin::Table head */}
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th className='min-w-100px'>شماره فروش</th>
                          <th className='min-w-140px'>گل</th>
                          <th className='min-w-140px'>غرفه</th>
                          <th className='min-w-140px'>تعداد</th>
                          <th className='min-w-140px'>قیمت</th>
                          <th className='min-w-140px'>مجموع مبلغ</th>
                          <th className='min-w-120px'>تاریخ</th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {saleHistory.map((item: any, ind: number) => {
                          return (
                            <tr key={ind}>
                              <td>
                                <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                  {item?.id}
                                </a>
                              </td>
                              <td>
                                <a
                                  href='#'
                                  className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                                >
                                  {item?.saleManagement?.flower?.title}
                                </a>
                              </td>
                              <td>
                                <a
                                  href='#'
                                  className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                                >
                                  {item?.saleManagement?.toOrganization?.title}
                                </a>
                              </td>
                              <td>
                                <a
                                  href='#'
                                  className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                                >
                                  {item?.amount}
                                </a>
                              </td>
                              <td>
                                <a
                                  href='#'
                                  className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                                >
                                  {priceSlice({data: Number(item?.finalPrice)})}
                                </a>
                              </td>

                              <td>
                                <a
                                  href='#'
                                  className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                                >
                                  {priceSlice({data: Number(item.amount * item?.finalPrice)})}
                                </a>
                              </td>
                              <td className='text-dark fw-bolder text-hover-primary fs-6'>
                                {convertDate({date: item.createdAt, type: 1})}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </>
                )}

                {/* end::Table */}
              </div>
              <div className='d-flex justify-content-center mt-10'>
                <Pagination
                  pageSize={state?.saleAllHistoryDashboradPagination.limit}
                  onChange={(e) => paginationOnChange(e)}
                  current={state?.saleAllHistoryDashboradPagination.page}
                  total={state?.saleAllHistoryDashboradPagination.total}
                />
                <Select
                  defaultValue={state.saleAllHistoryDashboradPagination.limit}
                  style={{width: 120, marginRight: '15px', height: '28px'}}
                  onChange={(e) => {
                    dispatch(
                      handle_variables({
                        saleAllHistoryDashboradPagination: {
                          ...state.saleAllHistoryDashboradPagination,
                          limit: e,
                        },
                      })
                    )
                    dispatch(
                      getAllSaleHistoryDashborad({
                        page: 1,
                        pageSize: e,
                      })
                    )
                  }}
                  options={[
                    {value: 10, label: 10},
                    {value: 20, label: 20},
                    {value: 50, label: 50},
                  ]}
                />
              </div>
            </>
          )}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default SaleHistoryDashborad
