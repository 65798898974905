import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
// import {handle_variables} from '../Redux/flowerSlice'
// import {handle_variables as main_handle_variables} from '../../../redux/mainSlice'
// import FlowerDefinesModal from './flowerDefineModal'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'

import useSelection from 'antd/es/table/hooks/useSelection'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {toast} from 'react-toastify'
import {Link, useHistory} from 'react-router-dom'
import {getGrades, gradeState} from '../../../grade/redux/gradeSlice'
import {authState} from '../../../auth/redux/AuthSlice'
import {convertDate} from '../../../../functions/convertDate'
import {financState, getCheques, handle_variables, updateCheque} from '../../redux/financSlice'
import ChequeRegistrationModal from './ChequeRegistrationModal'
import {priceSlice} from '../../../../functions/priceSlice'

const Cheque = () => {
  const dispatch = useDispatch()
  const state = useSelector(financState)
  const authS = useSelector(authState)
  const history = useHistory()

  const orgId = authS?.user?.organization?.id



  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getCheques(data))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        chequePagination: {...state.chequePagination, page: currentPage},
      })
    )
    dispatch(getCheques({page: currentPage, pageSize: state.chequePagination.limit}))
  }

  const handleStatus = (id: number) => {
    switch (id) {
      case 1:
        return <span className='badge badge-light-success'>پاس شده</span>

        break

      case 0:
        return <span className='badge badge-light-danger'>پاس نشده</span>

        break

      default:
        break
    }
  }

  const handleChequeTypeStatus = (id: number) => {
    switch (id) {
      case 1:
        return <span className='badge badge-light-info'>دریافتی</span>
      case 2:
        return <span className='badge badge-light-danger'>ارسالی</span>

      default:
        break
    }
  }

  return (
    <>
      {state.addChequeModalOpen ? <ChequeRegistrationModal edit={editMode} /> : null}

      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <Row>
            <Button
              onClick={() => {
                dispatch(handle_variables({addChequeModalOpen: true}))
                setEditMode(false)
              }}
              className='btn btn-primary'
            >
              ثبت چک
            </Button>
          </Row>
          <Row className='w-100 mt-6'>
            <Col lg={2}>
              <h3 className='card-title align-items-center'>
                <span className='card-label fw-bolder fs-3 mb-1'>چک ها</span>
              </h3>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 mt-6'>
          {/* begin::Table container */}
          {state.chequeTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <>
              <div className='table-responsive'>
                {/* begin::Table */}
                {state.cheques.length < 1 ? (
                  'موردی یافت نشد'
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-100px'>شماره چک</th>
                        <th className='min-w-140px'>مشتری</th>
                        <th className='min-w-140px'>نوع چک</th>
                        <th className='min-w-140px'>مبلغ چک(تومان)</th>
                        <th className='min-w-140px'>تاریخ چک</th>
                        <th className='min-w-140px'>بانک</th>
                        <th className='min-w-140px'>وضعیت چک</th>
                        <th className='min-w-140px'>تاریخ ثبت چک</th>
                        <th className='min-w-140px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.cheques.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item?.number}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.buyerUser?.firstName}
                                {''} {item?.buyerUser?.lastName}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {handleChequeTypeStatus(
                                  item?.buyerUser?.organization?.id == orgId
                                    ? 1
                                    : 2
                                )}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: Number(item?.amount)})}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {convertDate({date: item?.date, type: 1})}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.bank?.title}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {handleStatus(item?.chequeStatusId)}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {convertDate({date: item.createdAt, type: 1})}
                              </a>
                            </td>

                            <td>
                              {item?.chequeStatusId == 0 && new Date() > new Date(item?.date) && item?.buyerUser?.organization?.id == orgId  ? (
                                <button
                                  onClick={async (e) => {
                                    if (!state?.chequeUpdatedeLoading) {
                                      try {
                                        const payload = {
                                          id: item?.id,
                                          data: {
                                            chequeStatusId: 1,
                                          },
                                        }

                                        await dispatch(updateCheque(payload))
                                        dispatch(
                                          getCheques({
                                            page: state.chequePagination.page,
                                            pageSize: state.chequePagination.limit,
                                          })
                                        )
                                      } catch (error) {}
                                    }
                                  }}
                                  className='btn btn-primary btn-bg-light  btn-sm'
                                >
                                  {!state?.chequeUpdatedeLoading ? (
                                    'پاس شده'
                                  ) : (
                                    <GridLoader size={2} color='white' />
                                  )}
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                )}

                {/* end::Table */}
              </div>
              <div className='d-flex justify-content-center mt-10'>
                <Pagination
                  pageSize={state?.chequePagination.limit}
                  onChange={(e) => paginationOnChange(e)}
                  current={state?.chequePagination.page}
                  total={state?.chequePagination.total}
                />
                <Select
                  defaultValue={state.chequePagination.limit}
                  style={{width: 120, marginRight: '15px', height: '28px'}}
                  onChange={(e) => {
                    dispatch(
                      handle_variables({
                        chequePagination: {...state.chequePagination, limit: e},
                      })
                    )
                    dispatch(
                      getCheques({
                        page: 1,
                        pageSize: e,
                      })
                    )
                  }}
                  options={[
                    {value: 10, label: 10},
                    {value: 20, label: 20},
                    {value: 50, label: 50},
                  ]}
                />
              </div>
            </>
          )}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default Cheque
