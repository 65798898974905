import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
// import {handle_variables} from '../Redux/flowerSlice'
// import {handle_variables as main_handle_variables} from '../../../redux/mainSlice'
// import FlowerDefinesModal from './flowerDefineModal'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'

import useSelection from 'antd/es/table/hooks/useSelection'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {toast} from 'react-toastify'
import {Link, useHistory} from 'react-router-dom'
import {getGrades, gradeState} from '../../../grade/redux/gradeSlice'
import {authState} from '../../../auth/redux/AuthSlice'
import {convertDate} from '../../../../functions/convertDate'
import {financState, getFinancs, handle_variables} from '../../redux/financSlice'
import {priceSlice} from '../../../../functions/priceSlice'
import DefaultDatePicker from '../../../../components/DefaultDatePicker'

const FinancialAccount = () => {
  const dispatch = useDispatch()
  const state = useSelector(financState)
  const authS = useSelector(authState)
  const history = useHistory()

  const [filterDate, setFilterDate] = useState({from: '', to: ''})

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getFinancs(data))
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        financPagination: {...state.financPagination, page: currentPage},
      })
    )
    dispatch(getFinancs({page: currentPage, pageSize: state.financPagination.limit}))
  }

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <Row className='w-100 mt-6'>
            <Col lg={2}>
              <h3 className='card-title align-items-center'>
                <span className='card-label fw-bolder fs-3 mb-1'>حساب ها</span>
              </h3>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 mt-6'>
          {/* begin::Table container */}
          {state.financTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <>
              <Row className='my-5 d-flex justify-content-center'  >
                <Col md={2}>
                  <div className='financ-card d-flex flex-column justify-content-evenly align-items-center' style={{backgroundColor:"#ffcc80"}}>
                    <span className='fs-2 fw-bold'>فروخته شده</span>
                    <span className='fs-3 fw-bold'>
                    {priceSlice({data: +state?.financs?.totalBuyAmount})}</span>
                  </div>
                </Col>
                <Col md={2}>
                  <div className='financ-card d-flex flex-column justify-content-evenly align-items-center' style={{backgroundColor:"#a5d6a7"}}>
                    <span className='fs-2 fw-bold'>پرداخت شده</span>
                    <span className='fs-3 fw-bold'>
                    {priceSlice({data: +state?.financs?.totalPayments})}</span>
                  </div>
                </Col>
                <Col md={2}>
                  <div className='financ-card d-flex flex-column justify-content-evenly align-items-center' style={{backgroundColor:"#ffab91"}}>
                    <span className='fs-2 fw-bold'>چک</span>
                    <span className='fs-3 fw-bold'>
                    {priceSlice({data: +state?.financs?.totalCheques})}</span>
                  </div>
                </Col>
                <Col md={2}>
                  <div className='financ-card d-flex flex-column justify-content-evenly align-items-center' style={{backgroundColor:"#d7ccc8"}}>
                    <span className='fs-2 fw-bold'>حساب باز</span>
                    <span className='fs-3 fw-bold'>
                    {priceSlice({data: +state?.financs?.totalDemandOrDebt -  +state?.financs?.totalCheques})}</span>
                  </div>
                </Col>
                <Col md={2}>
                  <div className='financ-card d-flex flex-column justify-content-evenly align-items-center' style={{backgroundColor:"#cfd8dc"}}>
                    <span className='fs-2 fw-bold'>مجموع بدهی</span>
                    <span className='fs-3 fw-bold'>
                    {priceSlice({data: +state?.financs?.totalDemandOrDebt})}</span>
                  </div>
                </Col>
              </Row>
              <Row className='w-100 mt-15 mb-10'>
            <Col lg={4}>
              <DefaultDatePicker
                from={(e: string) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    from: e,
                  }))
                }
                to={(e: any) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    to: e,
                  }))
                }
                type='double'
              />
            </Col>
            <Col lg={1} className='mt-5 mt-lg-0'>
              <Button
                onClick={() => {
                  const data = {
                    page: state?.saleAllHistoryDashboradPagination?.page,
                    pageSize: state?.saleAllHistoryDashboradPagination?.limit,
                    fromDate: filterDate.from,
                    toDate: filterDate.to,
                  
                  }

                  dispatch(getFinancs(data))
                }}
              >
                جستجو
              </Button>
            </Col>
          </Row>
              <Row style={{marginTop:"30px"}}>
              <div className='table-responsive mt-5'>
                {/* begin::Table */}
                {state.financs?.organizations?.length < 1 ? (
                  'موردی یافت نشد'
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-140px'>نام برند</th>
                        <th className='min-w-140px'>فروخته شده (تومان)</th>
                        <th className='min-w-140px'>پرداخت شده (تومان)</th>
                        <th className='min-w-140px'> چک (تومان)</th>
                        <th className='min-w-140px'> حساب باز (تومان)</th>
                        <th className='min-w-140px'> کل بدهی (تومان)</th>
                        <th className='min-w-40px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.financs?.organizations?.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item?.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: item?.totalBuyAmount})}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: item?.totalPayments})}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: item?.totalCheques})}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: +item?.totalBuyAmount - +item?.totalPayments - +item?.totalCheques })}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: +item?.totalBuyAmount - +item?.totalPayments })}
                              </a>
                            </td>
                           
                            <td className='text-end'>
                              <Link
                                to={`/sell/precentage/request-details/${item.basketId}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen019.svg'
                                  className='svg-icon-3'
                                />
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                )}

                {/* end::Table */}
              </div>
              </Row>
              <div className='d-flex justify-content-center mt-10'>
                <Pagination
                  pageSize={state?.financPagination.limit}
                  onChange={(e) => paginationOnChange(e)}
                  current={state?.financPagination.page}
                  total={state?.financPagination.total}
                />
                <Select
                  defaultValue={state.financPagination.limit}
                  style={{width: 120, marginRight: '15px', height: '28px'}}
                  onChange={(e) => {
                    dispatch(
                      handle_variables({
                        financPagination: {...state.financPagination, limit: e},
                      })
                    )
                    dispatch(
                      getFinancs({
                        page: 1,
                        pageSize: e,
                      })
                    )
                  }}
                  options={[
                    {value: 10, label: 10},
                    {value: 20, label: 20},
                    {value: 50, label: 50},
                  ]}
                />
              </div>
            </>
          )}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default FinancialAccount
