import React from 'react'
import {Col, Container, Row} from 'react-bootstrap-v5'
import { useSelector } from 'react-redux'
import { authState } from '../../../auth/redux/AuthSlice'

export const ComponentToPrint = React.forwardRef(({data}: {data: any}, ref: any) => {
  const formatdate = new Date().toLocaleDateString('fa-IR')
  const time = new Date().toLocaleTimeString('fa-IR')

  const authS = useSelector(authState)

  const calculationTotalPrice = () => {
    if (data?.saleHistories.length > 0) {
      let totalPrice: any = null
      data?.saleHistories.map((x: any) => {
        totalPrice += x.price * x.amount
      })

      return totalPrice
    }
  }
  const calculationFinalTotalPrice = () => {
    if (data?.saleHistories.length > 0) {
      let totalPrice: any = null
      data?.saleHistories.map((x: any) => {
        totalPrice += x.finalPrice * x.amount
      })

      return totalPrice
    }
  }

  return (
    <div ref={ref}>
      <Container>
        <Row className='d-flex justify-content-center fs-4 my-3'>فاکتور فروش</Row>
        <Row className='d-flex justify-content-center fs-1 my-4'>{authS?.user?.organization?.title}</Row>
        <Row
          className='d-flex justify-content-center fs-4 my-4 p-3'
          style={{borderTop: '1px solid', borderBottom: '1px solid'}}
        >
          <Col md={3}>
            <span>شماره فاکتور</span>
            <span>{data?.id}</span>
          </Col>
          <Col md={9} className='d-flex justify-content-end'>
            <div className='me-3'>
              <span className='me-1'>ساعت:</span>
              <span>{time}</span>
            </div>
            <div>
              <span className='me-1'>تاریخ:</span>
              <span>{formatdate}</span>
            </div>
          </Col>
        </Row>
        <Row className='px-3'>
          {data?.saleHistories.length > 0 && (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-100px'>نام گل</th>
                  <th className='min-w-140px'>تعداد</th>
                  <th className='min-w-140px'>قیمت</th>
                  <th className='min-w-140px'>قیمت نهایی</th>
                  <th className='min-w-140px'>جمع</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data?.saleHistories.map((x: any, ind: number) => {
                  return (
                    <tr key={ind}>
                      <td>{x?.flowerName}</td>
                      <td>{x?.amount}</td>
                      <td>{x?.price}</td>
                      <td>{x?.finalPrice}</td>
                      <td>{x?.finalPrice * x?.amount}</td>
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
          )}
        </Row>
        <hr />
        <Row className='px-5'>
          <table className='table '>
            {/* begin::Table head */}

            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td className='px-2'>جمع کل</td>
                <td>{calculationTotalPrice()}</td>
              </tr>
              <tr>
                <td className='px-2'>تخفیف</td>
                <td>{calculationTotalPrice()-calculationFinalTotalPrice()}</td>
              </tr>
              <tr>
                <td className='px-2' style={{backgroundColor: '#000', color: '#fff'}}>
                  مبلغ قابل پرداخت
                </td>
                <td style={{backgroundColor: '#000', color: '#fff'}}>
                  {calculationFinalTotalPrice()}
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
        </Row>
      </Container>
    </div>
  )
})
