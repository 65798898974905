import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {AxiosError, AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {
  delete_role,
  delete_user,
  get_buyers,
  get_permission,
  get_role,
  get_role_update_data,
  get_user_update_data,
  get_users,
  set_buyer,
  set_role,
  set_role_permission,
  set_user,
  update_current_user,
  update_role,
  update_user,
} from './AccountApi'
import {UpdateRoleData} from '../../../typescript/interface'

interface CustomError extends AxiosResponse {
  response: {
    data: {
      message: string
    }
  }
}

let initialState = {
  permissions: [],
  selectPermissions: [],
  roles: [],
  roleTableLoading: false,
  addRoleModalOpen: false,
  roleAddLoading: false,
  roleUpdatedefaultValue: {},
  roleUpdatedeDataLoading: false,
  rolePagination: {
    page: 1,
    limit: 10,
  },
  users: [],
  userTableLoading: false,
  addUserModalOpen: false,
  userAddLoading: false,
  userUpdatedefaultValue: {},
  userUpdatedeDataLoading: false,
  userPagination: {
    page: 1,
    limit: 10,
  },

  buyers: [],
  buyerTableLoading: false,
  addBuyerModalOpen: false,
  buyerAddLoading: false,
  buyerUpdatedefaultValue: {},
  buyerUpdatedeDataLoading: false,
  buyerPagination: {
    page: 1,
    limit: 10,
  },

  currentUserUpdateLoading: false,
}

///////////////////////// Role /////////////////////////////
export const getPermissions = createAsyncThunk('account/getPermissions', async (data : any) => {
  try {
    const response = await get_permission(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const setRole = createAsyncThunk('account/setRole', async (data: {title: string}) => {
  try {
    const response = await set_role(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getRole = createAsyncThunk('account/getRole', async (data: any) => {
  try {
    const response = await get_role(data.page, data.pageSize)
    if (response) {
    }
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const deleteRole = createAsyncThunk('account/deleteRole', async (id: number) => {
  try {
    const response = await delete_role(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getRoleUpdateData = createAsyncThunk(
  'account/getRoleUpdateData',
  async (id: number) => {
    try {
      const response = await get_role_update_data(id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

export const updateRole = createAsyncThunk('account/updateRole', async (data: any) => {
  try {
    const response = await update_role(data.id, data.data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

///////////////////////// user /////////////////////////////////////

export const getUsers = createAsyncThunk('account/getUsers', async (data: any) => {
  try {
    const response = await get_users(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const setUser = createAsyncThunk('account/setUser', async (data: any) => {
  try {
    const response = await set_user(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const deleteUser = createAsyncThunk('account/deleteUser', async (id: number) => {
  try {
    const response = await delete_user(id)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const getUserUpdateData = createAsyncThunk(
  'account/getUserUpdateData',
  async (id: number) => {
    try {
      const response = await get_user_update_data(id)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

export const updateUser = createAsyncThunk('account/updateUser', async (data: any) => {
  try {
    const response = await update_user(data.id, data.data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

////////////////////////////// Current User //////////////////////////////////

export const updateCurrentUser = createAsyncThunk(
  'account/updateCurrentUser',
  async (data: any) => {
    try {
      const response = await update_current_user(data)
      return response
    } catch (err) {
      const customError = err as CustomError
      toast.error(customError?.response?.data?.message)
      Promise.reject(err)
      throw err
    }
  }
)

////////////////////////////// BUYER  //////////////////////////////////
export const getBuyers = createAsyncThunk('account/getBuyers', async (data: any) => {
  try {
    const response = await get_buyers(data.page, data.pageSize)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

export const setBuyer = createAsyncThunk('account/setBuyer', async (data: any) => {
  try {
    const response = await set_buyer(data)
    return response
  } catch (err) {
    const customError = err as CustomError
    toast.error(customError?.response?.data?.message)
    Promise.reject(err)
    throw err
  }
})

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    handle_variables: (state, action) => {
      const data = action.payload
      state = {...state, ...data}
      return state
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.fulfilled, (state, action) => {
        if (action.payload) {
          state.permissions = action.payload.data.data
        }
      })
      .addCase(getPermissions.rejected, (state, action) => {
        console.log('errorr', action)
      })
      .addCase(setRole.fulfilled, (state, action) => {
        if (action.payload) {
          const data = {
            roleId: action.payload.data.data.id,
            permissions: state.selectPermissions,
          }
          state.roleAddLoading = false
          state.addRoleModalOpen = false
          set_role_permission(data)
        }
      })
      .addCase(getRole.pending, (state, action) => {
        state.roleTableLoading = true
      })
      .addCase(getRole.fulfilled, (state, action) => {
        if (action.payload) {
          state.rolePagination = action.payload.data.pagination
          state.roleTableLoading = false
          state.roles = action.payload.data.data
        }
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        //  state.roles=state.roles.filter(role => role.id !== action.payload)
        toast.success('حذف با موفقیت انجام شد')
        // get_role()
      })
      .addCase(getRoleUpdateData.pending, (state, action) => {
        state.roleUpdatedeDataLoading = true
      })
      .addCase(getRoleUpdateData.fulfilled, (state, action: any) => {
        state.roleUpdatedeDataLoading = false
        state.roleUpdatedefaultValue = action.payload.data.data
      })
      .addCase(updateRole.fulfilled, (state, action: any) => {
        state.addRoleModalOpen = false
        state.roleAddLoading = false
      })
      .addCase(updateRole.rejected, (state, action: any) => {
        state.roleAddLoading = false
      })
      .addCase(getUsers.pending, (state, action) => {
        state.userTableLoading = true
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        if (action.payload) {
          state.userPagination = action.payload.data.pagination
          state.userTableLoading = false
          state.users = action.payload.data.data
        }
      })
      .addCase(setUser.fulfilled, (state, action) => {
        if (action.payload) {
          toast.success('با موفقیت کاربر جدید ایجاد شد')
          state.userAddLoading = false
          state.addUserModalOpen = false
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        //  state.roles=state.roles.filter(role => role.id !== action.payload)
        toast.success('حذف با موفقیت انجام شد')
        // get_role()
      })
      .addCase(getUserUpdateData.pending, (state, action) => {
        state.userUpdatedeDataLoading = true
      })
      .addCase(getUserUpdateData.fulfilled, (state, action: any) => {
        state.userUpdatedefaultValue = action.payload.data.data
        state.userUpdatedeDataLoading = false
      })
      .addCase(updateUser.fulfilled, (state, action: any) => {
        state.addUserModalOpen = false
        state.userAddLoading = false
      })
      .addCase(updateUser.rejected, (state, action: any) => {
        state.userAddLoading = false
      })
      .addCase(updateCurrentUser.fulfilled, (state, action: any) => {
        state.currentUserUpdateLoading = false
      })
      .addCase(updateCurrentUser.rejected, (state, action: any) => {
        state.currentUserUpdateLoading = false
      })
      .addCase(getBuyers.pending, (state, action) => {
        state.userTableLoading = true
      })
      .addCase(getBuyers.fulfilled, (state, action) => {
        if (action.payload) {
          state.buyerPagination = action.payload.data.pagination
          state.buyerTableLoading = false
          state.buyers = action.payload.data.data
        }
      })
      .addCase(setBuyer.fulfilled, (state, action) => {
        if (action.payload) {
          state.buyerAddLoading = false
          state.addBuyerModalOpen = false
        }
      })
  },
})

export const {handle_variables} = accountSlice.actions

export const accountState = (state: any) => state.account

export default accountSlice.reducer
