import {FC, useEffect, useState} from 'react'
import {Button, Col, Modal, Row} from 'react-bootstrap-v5'
import {addSellRequest} from '../../../../typescript/interface'
import {useDispatch, useSelector} from 'react-redux'
import {
  getBaskets,
  getLastBasket,
  getSellRequests,
  handle_variables,
  sellState,
  setBasket,
  setSellRequest,
  updateSellRequest,
} from '../../redux/sellSlice'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {authState} from '../../../auth/redux/AuthSlice'
import {flowerState, getOrganizationFlower} from '../../../flower/redux/flowerSlice'
import {cooperationState, getCoopRequest} from '../../../partners/Redux/cooperationSlice'
import {stringPrice} from '../../../../functions/stringPrice'
import {GridLoader} from 'react-spinners'
import {KTSVG} from '../../../../../_metronic/helpers'

var persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  fixNumbers = function (str: any) {
    if (typeof str === 'string') {
      for (var i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
      }
    }
    return str
  }

const PercentageSellModal = ({
  edit = false,
  defaultValue,
}: {
  edit?: boolean
  defaultValue?: addSellRequest
}) => {
  const state = useSelector(sellState)
  const flowerS = useSelector(flowerState)
  const coopS = useSelector(cooperationState)
  const authS = useSelector(authState)
  const dispatch = useDispatch()

  const [flowers, setFlowers] = useState<any>([])
  const [selectedFlowers, setSelectedFlowers] = useState<any>([])

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
      organizationId: authS.user.organizationId,
    }
    dispatch(getOrganizationFlower(data))

    const datacoop = {
      page: 1,
      pageSize: 50,
    }
    dispatch(getCoopRequest(datacoop))
  }, [])

  useEffect(() => {
    if (state.lastFlowers.length > 0) {
      const flowersSelected: any = []
      const flowers: any = []

      state.lastFlowers.map((item: any) => {
        if (item?.amount != 0) {
          flowersSelected.push(item?.flower)

          flowers.push({
            id: item?.flower?.id,
            title: item?.flower?.title,
            count: '',
            minPrice: '',
          })
        }
      })

      setSelectedFlowers(flowersSelected)
      setFlowers(flowers)
    }
  }, [state.lastFlowers])

  const organization = () => {
    if (coopS?.coops.length > 0) {
      const filteredData: any = coopS?.coops.filter((item: any) => item.cooperationStatusId == 1)

      const data: any = []

      if (filteredData.length > 0) {
        filteredData.map((x: any) => {
          if (authS.user.organizationId != x.fromOrganization.id) {
            data.push(x.fromOrganization)
          } else {
            data.push(x.toOrganization)
          }
        })
        return data
      }

      return false
    }
  }

  const deleteflower = (flower: any) => {
    const newFlowers = flowers.filter((f: any) => f.id !== flower)
    setFlowers(newFlowers)
    setSelectedFlowers(selectedFlowers.filter((option: any) => option.id !== flower))
  }

  const Schema = Yup.object().shape({
    // flower: Yup.string().required('گل نمی تواند خالی باشد'),
    buyer: Yup.string().required('غرفه دار نمی تواند خالی باشد'),
    // flowerCount: Yup.string().required('تعداد گل نمی تواند خالی باشد'),
    // flowers: Yup.array().of(
    //   Yup.object().shape({
    //     id: Yup.string().required('ID is required'), // Assuming ID is also required
    //     title: Yup.string().required('نام گل نمی تواند خالی باشد'),
    //     count: Yup.string().required('تعداد گل نمی تواند خالی باشد'),
    //   })
    // ),
  })

  const initialValues = {
    // flowers:
    //   edit && state.sellRequestUpdatedefaultValue?.flower?.id
    //     ? state.sellRequestUpdatedefaultValue?.flower?.id
    //     : [],
    buyer:
      edit && state.sellRequestUpdatedefaultValue?.toOrganization?.id
        ? state.sellRequestUpdatedefaultValue?.toOrganization?.id
        : undefined,
    // flowerCount:
    //   edit && state.sellRequestUpdatedefaultValue?.initialAmount
    //     ? state.sellRequestUpdatedefaultValue?.initialAmount
    //     : '',
    // lowerPrice:
    //   edit && state.sellRequestUpdatedefaultValue?.minimumPrice
    //     ? state.sellRequestUpdatedefaultValue?.minimumPrice
    //     : '',
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: (values) => {
      console.log('values', values)
      dispatch(handle_variables({flowerAddLoading: true}))

      //Condition for create and update
      if (edit) {
        //update
        // const data = {
        //   toOrganizationId: values.buyer,
        //   flowerId: values.flower,
        //   initialAmount: values.flowerCount,
        //   minimumPrice: values.lowerPrice,
        // }
        // const id = state.sellRequestUpdatedefaultValue.id
        // const info = {
        //   id,
        //   data,
        // }
        // dispatch(updateSellRequest(info))
      } else {
        //create
        // map flowers array and change format to send
        let saleRequests: any[] = []
        flowers.map((item: any) => {
          const obj = {
            flowerId: Number(item?.id),
            amount: Number(item?.count),
            minimumPrice: Number(item?.minPrice),
          }
          saleRequests.push(obj)
        })

        const data = {
          toOrganizationId: values.buyer,
          saleRequests,
          // initialAmount: values.flowerCount,
          // minimumPrice: values.lowerPrice === '' ? null : values.lowerPrice,
        }

        // dispatch(setSellRequest(data))
        dispatch(setBasket(data))
      }
      setTimeout(() => {
        dispatch(
          getBaskets({
            page: state.basketPagination.page,
            pageSize: state.basketPagination.limit,
          })
        )
      }, 1000)
    },
  })

  console.log('formik', formik)

  return (
    <>
      <Modal
        size='lg'
        show={state?.addBasketModalOpen}
        onHide={() => dispatch(handle_variables({addBasketModalOpen: false}))}
      >
        <Modal.Header>
          <Modal.Title>درخواست فروش</Modal.Title>
        </Modal.Header>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body>
            {coopS.coopTableLoading ||
            flowerS.organizationFlowerTableLoading ||
            state.sellRequestUpdatedeDataLoading ? (
              <div className='d-flex justify-content-center'>
                {' '}
                <GridLoader size={5} color={'gray'} />
              </div>
            ) : (
              <>
                <Row>
                  <Col md={6} className='mt-2'>
                    <label className='form-label fw-bolder text-dark fs-6'>غرفه دار</label>
                    <DefaultDropDown
                      name='buyer'
                      onChange={(e: {id: string | number; title: string}) => {
                        formik.setFieldValue('buyer', e.id)
                        dispatch(getLastBasket(e.id))
                      }}
                      type='default'
                      placeholder='غرفه دار'
                      options={organization()}
                      defaultValue={
                        edit && state.sellRequestUpdatedefaultValue?.toOrganization
                          ? state.sellRequestUpdatedefaultValue?.toOrganization
                          : undefined
                      }
                    />
                    {formik.touched.buyer && formik.errors.buyer && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='invalid-error-text' role='alert'>
                            {formik.errors.buyer}
                          </span>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md={6} className='mt-2'>
                    <label className='form-label fw-bolder text-dark fs-6'>گل</label>
                    <DefaultDropDown
                      name='flower'
                      notCloseMenu={true}
                      value={selectedFlowers}
                      onChange={async (e: [{id: string | number; title: string}]) => {
                        // set data in selectedFlowers
                        setSelectedFlowers(e)

                        //check dropDown not empty
                        if (e?.length > 0) {
                          //map dropDown array
                          e.map((item): any => {
                            //check dropDown value is exist in flowers array
                            const hasflowerExist = flowers.some(
                              (flower: {id: string | number}) => flower.id == item?.id
                            )

                            if (!hasflowerExist) {
                              //if not exist in flowers array add to this array

                              setFlowers([
                                ...flowers,
                                {
                                  id: item?.id,
                                  title: item?.title,
                                  count: '',
                                  minPrice: '',
                                },
                              ])
                            }
                          })

                          // setFlowers(cleanedFlowers)
                        }
                      }}
                      type='default'
                      isMulti={true}
                      placeholder='گل'
                      options={flowerS.organizationFlowers}
                      // defaultValue={
                      //   edit && state.sellRequestUpdatedefaultValue?.flower
                      //     ? state.sellRequestUpdatedefaultValue?.flower
                      //     : undefined
                      // }
                    />
                    {/* {formik.touched.flower && formik.errors.flower && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span className='invalid-error-text' role='alert'>
                            {formik.errors.flower}
                          </span>
                        </div>
                      </div>
                    )} */}
                  </Col>
                </Row>
                {flowers.map((row: any, index: any) => {
                  return (
                    <div key={index} className='my-4 d-flex'>
                      <Col md={4} className='mx-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>نام گل</label>
                        <input
                          type='text'
                          disabled
                          value={flowers[index].title}
                          className={'form-control form-control-lg form-control-solid no-spinners'}
                          placeholder='نام گل'
                        />
                      </Col>

                      <Col md={4} className='mx-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>تعداد</label>
                        <input
                          type='number'
                          style={{direction: 'rtl'}}
                          className={'form-control form-control-lg form-control-solid no-spinners'}
                          // {...formik.getFieldProps(`flowerCount[${index}].count`)}
                          onChange={(e) => {
                            const updatedFlowers = [...flowers]
                            updatedFlowers[index].count = e.target.value
                            setFlowers(updatedFlowers)
                            // formik.setFieldValue(`flowerCount[${index}].count`, e.target.value)
                          }}
                          value={flowers[index].count}
                          placeholder='تعداد'
                        />
                        {/* {formik.values.flowers.length > 0
                          ? formik.errors.flowers[index].count && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span className='invalid-error-text' role='alert'>
                                    {formik.errors.flowers[index].count}
                                  </span>
                                </div>
                              </div>
                            )
                          : undefined} */}
                      </Col>
                      <Col md={3} className='mx-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>
                          {' '}
                          حداقل قیمت فروش(تومان)
                        </label>
                        <input
                          type='number'
                          style={{direction: 'rtl'}}
                          className={'form-control form-control-lg form-control-solid no-spinners'}
                          // {...formik.getFieldProps(`flowerCount[${index}].minPrice`)}
                          onChange={(e) => {
                            const updatedFlowers = [...flowers]
                            updatedFlowers[index].minPrice = e.target.value
                            setFlowers(updatedFlowers)
                          }}
                          value={flowers[index].minPrice}
                          placeholder='حداقل قیمت فروش(تومان)'
                        />
                        <div className='text-primary'>
                        {stringPrice({
                                data: flowers[index]?.minPrice,
                              })}
                        </div>
                      
                      </Col>
                      <Col md={1} className='mx-1 d-flex mt-9'>
                        <div
                          onClick={() => deleteflower(row.id)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </div>
                      </Col>
                    </div>
                  )
                })}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(handle_variables({addBasketModalOpen: false}))
              }}
              variant='secondary'
            >
              انصراف
            </Button>
            <Button
              onClick={() => {
                // formik.setFieldValue('flowers', flowers)
              }}
              type='submit'
              variant='primary'
            >
              ثبت
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default PercentageSellModal
