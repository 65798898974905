import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import PercentageSellModal from './PercentageSellModal'
import {useDispatch, useSelector} from 'react-redux'
import Pagination from 'rc-pagination'
import {allData} from './constants'
import {
  deleteCart,
  getCart,
  getSellRequestUpdateData,
  getSellRequests,
  handle_variables,
  sellState,
} from '../../redux/sellSlice'
import {Link, useHistory, useParams} from 'react-router-dom'
import {GridLoader} from 'react-spinners'
import {Select, Table, TableColumnsType} from 'antd'
import {convertDate} from '../../../../functions/convertDate'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'
import {toast} from 'react-toastify'
import {authState} from '../../../auth/redux/AuthSlice'
import OrderEditModal from './OrderEditModal'
import {priceSlice} from '../../../../functions/priceSlice'

interface RouteParams {
  id: string // Assuming id is a string
  // Add more parameters if needed
}

interface DataType {
  key: any
  flowerName: any
  count: any
  flowerPrice: any
  flowerTotalPrice: any
}

const OrderDetails = () => {
  const dispatch = useDispatch()
  const state = useSelector(sellState)
  const authS = useSelector(authState)
  const history = useHistory()

  const [tableData, setTableData] = useState<DataType[]>([])
  const [deleteComponentShow, setDeleteComponentShow] = useState(false)

  const {id} = useParams<RouteParams>()

  useEffect(() => {
    dispatch(getCart(id))
  }, [])

  useEffect(() => {
    if (state?.cartDetails?.saleHistories?.length > 0) {
      setTableData([])

      state?.cartDetails?.saleHistories.map((x: any) => {
        const data = {
          key: x?.id,
          flowerName: x?.saleManagement?.flower?.title,
          count: x?.amount,
          flowerPrice: priceSlice({data: Number(x?.price)}),
          finalPrice: priceSlice({data: Number(x?.finalPrice)}),
          flowerTotalPrice: x?.finalPrice * x?.amount,
        }

        setTableData((prevArray) => [...prevArray, data])
      })
    }
  }, [state?.cartDetails])

  const columns: TableColumnsType<DataType> = [
    {
      title: 'گل',
      dataIndex: 'flowerName',
      key: 'flowerName',
      // render: (text) => <a>{text}</a>,
    },

    {
      title: 'تعداد',
      dataIndex: 'count',
      key: 'count',
      // responsive: ['lg'],
    },
    {
      title: 'قیمت',
      dataIndex: 'flowerPrice',
      key: 'flowerPrice',
      // responsive: ['lg'],
    },
    {
      title: 'قیمت فروش',
      dataIndex: 'finalPrice',
      key: 'finalPrice',
      // responsive: ['lg'],
    },
    {
      title: 'قیمت کل',
      dataIndex: 'flowerTotalPrice',
      key: 'flowerTotalPrice',
      // responsive: ['lg'],
    },
  ]

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}

        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>جزییات سفارش</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>
          <div
            onClick={() => {
              history.goBack()
            }}
            style={{flexDirection: 'column', cursor: 'pointer'}}
            className='d-flex justify-content-end'
          >
            بازگشت
            <i
              className='bi bi-arrow-left'
              style={{color: '#000', fontSize: '23px', textAlign: 'center'}}
            ></i>
          </div>
        </div>

        <div>
          {state.orderEditModalOpen ? <OrderEditModal /> : null}

          <Button
            onClick={() => {
              dispatch(handle_variables({orderEditModalOpen: true}))
              // setEditMode(false)
            }}
            className='percentage-sell-btn'
          >
            ویرایش
          </Button>

          <Button
            onClick={() => {
              setDeleteComponentShow(true)
            }}
            className='percentage-sell-btn btn-danger mx-5'
          >
            حذف سفارش
          </Button>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        {state.cartDetailsLoading ? (
          <div className='w-100 d-flex justify-content-center my-6'>
            <GridLoader size={5} color={'gray'} />
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='w-100 d-flex justify-content-center align-items-center'>
              <span className='fs-5' style={{fontWeight: '600'}}>
                شماره سفارش
              </span>
              <span className='badge fs-4 badge-light-primary ms-2'>{state?.cartDetails?.id}</span>
            </div>
            <div className='sell-request-details-box'>
              <Row>
                <Col md={4} className='my-3'>
                  <span className='details-title-info'>{'قیمت کل '}</span>
                  <span>{priceSlice({data: Number(state?.cartDetails?.price)})}</span>
                </Col>
                <Col md={4} className='my-3'>
                  <span className='details-title-info'>{'تخفیف'}</span>
                  <span>{priceSlice({data: Number(state?.cartDetails?.discount)})}</span>
                </Col>
                <Col md={4} className='my-3'>
                  <span className='details-title-info'>{'مبلغ نهایی '}</span>
                  <span>
                    {priceSlice({
                      data: Number(state?.cartDetails?.price - state?.cartDetails?.discount),
                    })}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className='my-3' md={4}>
                  <span className='details-title-info'>{'مبلغ پرداخت نقد '}</span>
                  <span>{priceSlice({data: Number(state?.cartDetails?.cash)})}</span>
                </Col>
                <Col className='my-3 d-flex' md={4}>
                  <span className='details-title-info'>{'تاریخ '}</span>
                  <span>{convertDate({date: state?.cartDetails?.createdAt, type: 1})}</span>
                </Col>
                <Col className='my-3 d-flex' md={4}>
                  <span className='details-title-info'>{'مشتری'}</span>
                  <span>
                    {state?.cartDetails?.buyerUser?.firstName}{' '}
                    {state?.cartDetails?.buyerUser?.lastName}
                  </span>
                </Col>
              </Row>
            </div>

            <div className='my-5'>
              <Table
                columns={columns}
                dataSource={tableData.length > 0 ? tableData : []}
                pagination={false}
                scroll={{x: true}}
                style={{fontFamily: 'IRANSans'}}
              />
            </div>

            {/* end::Table container */}
          </div>
        )}
        <DeleteConfirmModal
          text={'آیا از پاک کردن این سفارش مطمعن هستید؟'}
          show={deleteComponentShow}
          close={() => setDeleteComponentShow(false)}
          onDelete={async () => {
            try {
              await dispatch(deleteCart(+id))
              history.push('/sell/precentage/order')
            } catch (error) {}
          }}
        />
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}

export default OrderDetails
