import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
// import {handle_variables} from '../Redux/flowerSlice'
// import {handle_variables as main_handle_variables} from '../../../redux/mainSlice'
// import FlowerDefinesModal from './flowerDefineModal'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'

import useSelection from 'antd/es/table/hooks/useSelection'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {toast} from 'react-toastify'
import {getAllCart, getSellManagements, handle_variables, sellState} from '../../redux/sellSlice'
import PriceModal from './PriceModal'
import GradeModal from './GradeModal'
import {Link, useHistory} from 'react-router-dom'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {getGrades, gradeState} from '../../../grade/redux/gradeSlice'
import ChangeFlowersPriceModal from './ChangeFlowersPriceModal'
import {authState} from '../../../auth/redux/AuthSlice'
import {convertDate} from '../../../../functions/convertDate'
import {accountState, getBuyers} from '../../../accounts/Redux/AccountSlice'
import DefaultDatePicker from '../../../../components/DefaultDatePicker'
import {priceSlice} from '../../../../functions/priceSlice'

const Orders = () => {
  const dispatch = useDispatch()
  const state = useSelector(sellState)
  const accountS = useSelector(accountState)
  const history = useHistory()

  const [buyersExist, setBuyersExist] = useState(false)
  const [buyer, setBuyer] = useState([])
  const [orderId, setOrderId] = useState(0)
  const [buyerId, setBuyerId] = useState(0)
  const [filterDate, setFilterDate] = useState({from: '', to: ''})

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getAllCart(data))

    //get buyers
    dispatch(getBuyers({page: 1, pageSize: 500}))
  }, [])

  if (!buyersExist && accountS?.buyers?.length > 0) {
    const mappedArray = accountS?.buyers?.map((item: any) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }))
    setBuyer(mappedArray)
    setBuyersExist(true)
  }

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        cartPagination: {...state.cartPagination, page: currentPage},
      })
    )
    dispatch(getAllCart({page: currentPage, pageSize: state.cartPagination.limit}))
  }

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <Row>
            <Button
              onClick={() => history.push(`/sell/sales-registration`)}
              className='btn btn-primary'
            >
              ثبت سفارش
            </Button>
          </Row>
          <Row className='w-100 mt-6'>
            <Col lg={2}>
              <h3 className='card-title align-items-center'>
                <span className='card-label fw-bolder fs-3 mb-1'>سفارشات</span>
              </h3>
            </Col>
          </Row>
          <Row className='w-100 mt-5 mb-8'>
            <Col lg={2}>
              <input
                name='brandName'
                className={'form-control form-control-lg form-control-solid'}
                type='text'
                onChange={(e: any) => {
                  setOrderId(+e.target.value)
                }}
                placeholder='شماره سفارش'
              />
            </Col>
            <Col lg={3}>
              <DefaultDropDown
                name='buyer'
                onChange={(e: {value: number; label: string}) => {
                  setBuyerId(+e.value)
                }}
                type='default'
                placeholder='خریدار'
                options={buyer}
              />
            </Col>
            <Col lg={4}>
              <DefaultDatePicker
                from={(e: string) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    from: e,
                  }))
                }
                to={(e: any) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    to: e,
                  }))
                }
                type='double'
              />
            </Col>
            <Col lg={1} className='mt-5 mt-lg-0'>
              <Button
                onClick={() => {
                  const data = {
                    page: state?.cartPagination?.page,
                    pageSize: state?.cartPagination?.limit,
                    orderId: orderId != 0 ? orderId : undefined,
                    buyerId: buyerId != 0 ? buyerId : undefined,
                    fromDate: filterDate.from,
                    toDate: filterDate.to,
                  }
                  dispatch(getAllCart(data))
                }}
              >
                جستجو
              </Button>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 mt-6'>
          {/* begin::Table container */}
          {state.cartTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <>
              <div className='table-responsive'>
                {/* begin::Table */}
                {state.carts.length < 1 ? (
                  'موردی یافت نشد'
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-100px'>شماره سفارش</th>
                        <th className='min-w-140px'>خریدار</th>
                        <th className='min-w-140px'> مبلغ سفارش</th>
                        <th className='min-w-140px'>مبلغ پرداختی نقد</th>
                        {/* <th className='min-w-140px'>تخفیف</th> */}
                        <th className='min-w-140px'>تاریخ</th>
                        <th className='min-w-140px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.carts.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item?.id}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.buyerUser?.firstName}
                                {''} {item?.buyerUser?.lastName}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: Number(item?.price)})}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: Number(item?.cash)})}
                              </a>
                            </td>

                            {/* <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.discount}
                              </a>
                            </td> */}

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {convertDate({date: item.createdAt, type: 1})}
                              </a>
                            </td>

                            <td className='text-end'>
                              <Link
                                to={`/sell/precentage/order-details/${item.id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen019.svg'
                                  className='svg-icon-3'
                                />
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                )}

                {/* end::Table */}
              </div>
              <div className='d-flex justify-content-center mt-10'>
                <Pagination
                  pageSize={state?.cartPagination.limit}
                  onChange={(e) => paginationOnChange(e)}
                  current={state?.cartPagination.page}
                  total={state?.cartPagination.total}
                />
                <Select
                  defaultValue={state.cartPagination.limit}
                  style={{width: 120, marginRight: '15px', height: '28px'}}
                  onChange={(e) => {
                    dispatch(
                      handle_variables({
                        cartPagination: {...state.cartPagination, limit: e},
                      })
                    )
                    dispatch(
                      getAllCart({
                        page: 1,
                        pageSize: e,
                      })
                    )
                  }}
                  options={[
                    {value: 10, label: 10},
                    {value: 20, label: 20},
                    {value: 50, label: 50},
                  ]}
                />
              </div>
            </>
          )}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default Orders
