/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector} from 'react-redux'
import {authState} from '../../../../app/modules/auth/redux/AuthSlice'

export function AsideMenuMain() {
  const intl = useIntl()

  const state = useSelector(authState)

  const isFarmer = state?.user?.organization?.organizationType?.id == 1 ? true : false
  const isExhibitor = state?.user?.organization?.organizationType?.id == 2 ? true : false

  const isAdmin = state?.user?.roles[0]?.title == 'admin' ? true : false

  const [perimissions, setPermissions] = useState<any[]>([])

  useLayoutEffect(() => {
    if (state) {
      // state?.user?.roles[0]?.permissions.map((item: any) => {
      //   setPermissions((prevState) => [...prevState, item.slug])
      // })
      state?.user?.roles?.map((item: any) => {
        item?.permissions.map((x: any) => {
          setPermissions((prevState) => [...prevState, x.slug])
        })
      })
    }
  }, [])

  // console.log(perimissions)

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>منو</span>
        </div>
      </div>

      {perimissions.includes('saleRequestManagement') ||
      perimissions.includes('saleManagementManagement') ? (
        <AsideMenuItemWithSub
          to='/sell'
          title='فروش'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          {/* <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}> */}
          {perimissions.includes('saleRequestManagement') ? (
            <AsideMenuItem
              to='/sell/precentage/request'
              title={
                state?.user?.organization?.organizationType?.id != 2
                  ? 'درخواست فروش'
                  : 'لیست دریافتی ها'
              }
              hasBullet={true}
            />
          ) : null}
          {perimissions.includes('saleManagementManagement') ? (
            <AsideMenuItem to='/sell/precentage/manegment' title='مدیریت فروش' hasBullet={true} />
          ) : null}
          {perimissions.includes('saleManagementManagement') &&
          state?.user?.organization?.organizationType?.id != 1 ? (
            <AsideMenuItem to='/sell/precentage/order' title='سفارشات' hasBullet={true} />
          ) : null}
          {perimissions.includes('saleManagementManagement') &&
          state?.user?.organization?.organizationType?.id != 1 ? (
            <AsideMenuItem
              to='/sell/precentage/all-sale-history'
              title='سابقه فروش'
              hasBullet={true}
            />
          ) : null}
          {perimissions.includes('saleManagementManagement') && isFarmer ? (
            <AsideMenuItem
              to='/sell/precentage/sale-history-dashboard'
              title='گزارش فروش'
              hasBullet={true}
            />
          ) : null}
          {/* <AsideMenuItem to='/crafted/pages/profile/projects' title='نقدی' hasBullet={true} /> */}
        </AsideMenuItemWithSub>
      ) : null}

      {perimissions.includes('financeManagement') && (
        <AsideMenuItemWithSub
          to='/financ'
          title='حسابرسی'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          <AsideMenuItem to='/financ/financial-account' title='حساب ها' hasBullet={true} />
          <AsideMenuItem to='/financ/cheque' title='چک ها' hasBullet={true} />
          <AsideMenuItem to='/financ/payment' title='پرداخت های نقد' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {(perimissions.includes('flowerDefineManagement') ||
        perimissions.includes('allPermissions') ||
        perimissions.includes('flowerManagement')) &&
      state?.user?.organization?.organizationType?.id != 2 ? (
        <AsideMenuItemWithSub
          to='/crafted/pages'
          title='مدیریت گل ها'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          {isAdmin ? (
            <AsideMenuItem to='/crafted/pages/flower/defines' title='تعریف گل' hasBullet={true} />
          ) : null}
          {perimissions.includes('flowerManagement') ? (
            <AsideMenuItem
              to='/crafted/pages/flower/organization'
              title='گل های من'
              hasBullet={true}
            />
          ) : null}
        </AsideMenuItemWithSub>
      ) : null}

      {perimissions.includes('cooperationManagement') ? (
        <AsideMenuItemWithSub
          to='/cooperation'
          title='همکاری'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to='/cooperation/list'
            title={
              state?.user?.organization?.organizationType?.id == 1
                ? 'لیست غرفه داران'
                : 'لیست گلخانه داران'
            }
            hasBullet={true}
          />
          <AsideMenuItem to='/cooperation/request' title='لیست درخواست ها' hasBullet={true} />
        </AsideMenuItemWithSub>
      ) : null}

      {/* <AsideMenuItem
        to='/return-flower'
        icon='/media/icons/duotune/art/art002.svg'
        title='مرجوع گل'
        fontIcon='bi-app-indicator'
      /> */}

      {isAdmin ? (
        <AsideMenuItemWithSub
          to='/user-managment'
          title='مدیریت کاربران'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem
            to='/user-managment/register-verification'
            title='درخواست های ثبت نام'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}

      {isAdmin ? (
        <AsideMenuItem
          to='/grade'
          icon='/media/icons/duotune/art/art002.svg'
          title='درجه'
          fontIcon='bi-app-indicator'
        />
      ) : null}

      {isAdmin ? (
        <AsideMenuItem
          icon='/media/icons/duotune/communication/com006.svg'
          to='/accounts/role'
          title='نقش'
        />
      ) : null}

      {perimissions.includes('userManagement')  ? (
        <AsideMenuItemWithSub
          to='/accounts'
          title='مدیریت کاربری'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        >
          {perimissions.includes('userManagement') ? (
            <div>
              <AsideMenuItem to='/accounts/overview' title='نمایش اطاعات کاربری' hasBullet={true} />
              <AsideMenuItem
                to='/accounts/settings'
                title='تنظیمات اطلاعات کاربری'
                hasBullet={true}
              />

              <AsideMenuItem to='/accounts/adduser' title='کاربران' hasBullet={true} />
            </div>
          ) : null}

          {perimissions.includes('userManagement') &&
          state?.user?.organization?.organizationType?.id != 1 ? (
            <div>
              <AsideMenuItem to='/accounts/buyer' title='خریداران' hasBullet={true} />
            </div>
          ) : null}
        </AsideMenuItemWithSub>
      ) : null}

      {/* <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
