import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
// import {handle_variables} from '../Redux/flowerSlice'
// import {handle_variables as main_handle_variables} from '../../../redux/mainSlice'
// import FlowerDefinesModal from './flowerDefineModal'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'

import useSelection from 'antd/es/table/hooks/useSelection'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {toast} from 'react-toastify'
import {
  getFlowerHistories,
  getSellManagements,
  handle_variables,
  sellState,
} from '../../redux/sellSlice'
import PriceModal from './PriceModal'
import GradeModal from './GradeModal'
import {Link, useHistory} from 'react-router-dom'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {getGrades, gradeState} from '../../../grade/redux/gradeSlice'
import ChangeFlowersPriceModal from './ChangeFlowersPriceModal'
import {authState} from '../../../auth/redux/AuthSlice'
import DefaultDatePicker from '../../../../components/DefaultDatePicker'
import {accountState, getBuyers} from '../../../accounts/Redux/AccountSlice'
import {convertDate} from '../../../../functions/convertDate'
import {flowerState, getFlower} from '../../../flower/redux/flowerSlice'
import {cooperationState, getCoopRequest} from '../../../partners/Redux/cooperationSlice'
import {priceSlice} from '../../../../functions/priceSlice'

const AllSaleHstory = () => {
  const dispatch = useDispatch()
  const state = useSelector(sellState)
  const authS = useSelector(authState)
  const flowerS = useSelector(flowerState)
  const coopS = useSelector(cooperationState)

  const accountS = useSelector(accountState)
  const [filterDate, setFilterDate] = useState({from: '', to: ''})
  const [buyersExist, setBuyersExist] = useState(false)
  const [buyer, setBuyer] = useState([])
  const [coops, setCoops] = useState<any[]>([])
  const [flowerIdsFilter, setFlowerIdsFilter] = useState<any[]>([])
  const [fromOrganizationIdsFilter, setFromOrganizationIdsFilter] = useState<any[]>([])
  const [buyerUserIdsFilter, setBuyerUserIdsFilter] = useState<any[]>([])

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getFlowerHistories(data))
  }, [])

  useEffect(() => {
    // get buyers
    dispatch(getBuyers({page: 1, pageSize: 500}))

    //get flowers
    dispatch(
      getFlower({
        page: 1,
        pageSize: 500,
      })
    )

    //get cooprations
    dispatch(
      getCoopRequest({
        page: 1,
        pageSize: 1000,
      })
    )
  }, [])

  useEffect(() => {
    if (coopS?.coops.length > 0) {
      setCoops([])
      coopS?.coops.map((x: any) => {
        if (x?.cooperationStatusId == 1) {
          setCoops((prevData) => [
            ...prevData,
            x?.fromOrganization?.id != authS?.user?.id ? x?.fromOrganization : x?.toOrganization,
          ])
        }
      })
    }
  }, [coopS?.coops])

  if (!buyersExist && accountS?.buyers?.length > 0) {
    const mappedArray = accountS?.buyers?.map((item: any) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
    }))
    setBuyer(mappedArray)
    setBuyersExist(true)
  }

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        flowerHistoryPagination: {...state.flowerHistoryPagination, page: currentPage},
      })
    )
    dispatch(getFlowerHistories({page: currentPage, pageSize: state.flowerHistoryPagination.limit}))
  }

  return (
    <>
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <Row className='w-100 mb-8'>
            <Col lg={3}>
              <label className='form-label fw-bolder text-dark fs-6'>گل</label>
              <DefaultDropDown
                name='flower'
                onChange={(e: any) => {
                  if (e.length > 0) {
                    const dataArr: any = []
                    e.map((x: any) => {
                      dataArr.push(x.id)
                    })
                    setFlowerIdsFilter(dataArr)
                  } else {
                    setFlowerIdsFilter([])
                  }
                }}
                type='default'
                isMulti={true}
                placeholder='گل'
                options={flowerS.flowers}
              />
            </Col>
            <Col lg={3}>
              <label className='form-label fw-bolder text-dark fs-6'>مشتری</label>
              <DefaultDropDown
                name='buyer'
                onChange={(e: any) => {
                  if (e.length > 0) {
                    const dataArr: any = []
                    e.map((x: any) => {
                      dataArr.push(x.value)
                    })
                    setBuyerUserIdsFilter(dataArr)
                  } else {
                    setBuyerUserIdsFilter([])
                  }
                }}
                type='default'
                options={buyer}
                isMulti={true}
                placeholder='مشتری'
              />
            </Col>
            <Col lg={3}>
              <label className='form-label fw-bolder text-dark fs-6'>گلخانه</label>
              <DefaultDropDown
                name='fromOrganization'
                onChange={(e: any) => {
                  if (e.length > 0) {
                    const dataArr: any = []
                    e.map((x: any) => {
                      dataArr.push(x.id)
                    })
                    setFromOrganizationIdsFilter(dataArr)
                  } else {
                    setFromOrganizationIdsFilter([])
                  }
                }}
                type='default'
                isMulti={true}
                placeholder='گلخانه'
                options={coops}
              />
            </Col>
          </Row>
          <Row className='w-100'>
            <Col lg={2}>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>لیست فروش کلی</span>
              </h3>
            </Col>

            <Col lg={4}>
              <DefaultDatePicker
                from={(e: string) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    from: e,
                  }))
                }
                to={(e: any) =>
                  setFilterDate((prevFilterDate) => ({
                    ...prevFilterDate,
                    to: e,
                  }))
                }
                type='double'
              />
            </Col>
            <Col lg={1} className='mt-5 mt-lg-0'>
              <Button
                onClick={() => {
                  const data = {
                    page: state.flowerHistoryPagination.page,
                    pageSize: state.flowerHistoryPagination.limit,
                    fromDate: filterDate.from,
                    toDate: filterDate.to,
                    buyerUserId:
                      buyerUserIdsFilter.length > 0 ? JSON.stringify(buyerUserIdsFilter) : [],
                    fromOrganizationId:
                      fromOrganizationIdsFilter.length > 0
                        ? JSON.stringify(fromOrganizationIdsFilter)
                        : [],
                    flowerId: flowerIdsFilter.length > 0 ? JSON.stringify(flowerIdsFilter) : [],
                  }
                  dispatch(getFlowerHistories(data))
                }}
              >
                جستجو
              </Button>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 mt-6'>
          {state.getFlowerHistoryLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <div className='table-responsive'>
              {/* begin::Table */}
              {state.flowerHistories?.length < 1 ? (
                'موردی یافت نشد'
              ) : (
                <>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {/* <th className='w-25px'>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value='1'
                        data-kt-check='true'
                        data-kt-check-target='.widget-13-check'
                      />
                    </div>
                  </th> */}
                        <th className='min-w-150px'>شماره سفارش</th>
                        <th className='min-w-120px'>گل</th>
                        <th className='min-w-120px'>خریدار</th>
                        <th className='min-w-120px'>تعداد</th>
                        <th className='min-w-120px'>قیمت(تومان)</th>
                        <th className='min-w-120px'>تاریخ</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.flowerHistories?.map((item: any, ind: number) => {
                        return (
                          <tr>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item?.cartId}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.saleManagement?.flower?.title}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.buyerUser?.firstName} {item?.buyerUser?.lastName}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.amount}
                              </a>
                              {/* <span className='text-muted fw-bold text-muted d-block fs-7'>Code: Paid</span> */}
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: Number(item.finalPrice)})}
                              </a>
                            </td>

                            <td className='text-dark fw-bolder text-hover-primary fs-6'>
                              {convertDate({date: item.createdAt, type: 1})}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                </>
              )}
              {/* end::Table */}
            </div>
          )}

          {/* end::Table container */}

          <div className='d-flex justify-content-center mt-10'>
            <Pagination
              pageSize={state?.flowerHistoryPagination.limit}
              onChange={(e) => paginationOnChange(e)}
              current={state?.flowerHistoryPagination.page}
              total={state?.flowerHistoryPagination.total}
            />
            <Select
              defaultValue={state.flowerHistoryPagination.limit}
              style={{width: 120, marginRight: '15px', height: '28px'}}
              onChange={(e) => {
                dispatch(
                  handle_variables({
                    flowerHistoryPagination: {...state.flowerHistoryPagination, limit: e},
                  })
                )
                dispatch(
                  getFlowerHistories({
                    page: 1,
                    pageSize: e,
                  })
                )
              }}
              options={[
                {value: 10, label: 10},
                {value: 20, label: 20},
                {value: 50, label: 50},
                // { value: 'disabled', label: 'Disabled', disabled: true },
              ]}
            />
          </div>

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default AllSaleHstory
