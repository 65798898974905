import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {cooperationState, getOrg, handle_variables} from '../Redux/cooperationSlice'
import {GridLoader} from 'react-spinners'
import Pagination from 'rc-pagination'
import {Select} from 'antd'
import CooperationRequestModal from './CooperationRequestModal'
import { authState } from '../../auth/redux/AuthSlice'
import { Button, Col, Row } from 'react-bootstrap-v5'
import DefaultDropDown from '../../../components/DefaultDropDown'
import { getProvince, getTownship, mainState } from '../../../redux/mainSlice'

const CooperationList = () => {
  const dispatch = useDispatch()
  const state = useSelector(cooperationState)
  const authS = useSelector(authState)
  const mainS = useSelector(mainState)

  const [toOrgId, setToOrgId] = useState(0)
  const [province, setProvince] = useState({id: 0, title: ''})
  const [township, setTownship] = useState({id: 0, title: ''})
  const [brandName, setBrandName] = useState('')


  const isFarmer = authS?.user?.organization?.organizationType?.id == 1 ? true : false

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getOrg(data))

    dispatch(getProvince())
  }, [])

  const paginationOnChange = (currentPage: number) => {
    dispatch(handle_variables({orgPagination: {...state.orgPagination, page: currentPage}}))
    setTimeout(() => {
      dispatch(getOrg({page: currentPage, pageSize: state.orgPagination.limit}))
    }, 1000)
  }

  return (
    <>
      {state.CoopRequestModalOpen ? <CooperationRequestModal toOrgId={toOrgId} /> : null}
      <div className={`card mb-5 mb-xl-8`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>
            {authS?.user?.organization.organizationType?.id == 1 ? "لیست غرفه داران" : "لیست گلخانه داران"}
              </span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 orders</span> */}
          </h3>

          <Row className='w-100 mt-5 mb-8'>
          <Col lg={3}>
              <input
                name='brandName'
                className={'form-control form-control-lg form-control-solid'}
                type='text'
                onChange={(e: any) => {
                  setBrandName(e.target.value)
                }}
                placeholder='نام برند'
              />
            </Col>
            <Col lg={3}>
              <DefaultDropDown
                name='province'
                onChange={(e: {id: number; title: string}) => {
                  setProvince({id: e.id, title: e.title})
                  dispatch(getTownship(e.id))
                }}
                type='default'
                placeholder='استان'
                options={mainS.provinces.length > 0 && mainS.provinces}
              />
            </Col>
            <Col lg={3}>
              <DefaultDropDown
                 name='township'
                 onChange={(e: {id: number; title: string}) => {
                  setTownship({id: e.id, title: e.title})
                 }}
                 type='default'
                 disabled={province.title != '' ? false : true}
                 placeholder='شهرستان'
                 options={mainS.townships.length > 0 && mainS.townships}
            
              />
            </Col>
            <Col lg={1} className='mt-5 mt-lg-0'>
              <Button

                onClick={() => {
                  const data = {
                    page: state.orgPagination.page,
                    pageSize: state.orgPagination.limit,
                    province: province.id != 0 ? province.id : undefined ,
                    township: township.id != 0 ? township.id : undefined ,
                    brandName: brandName != '' ? brandName : undefined
                  }

                  dispatch(getOrg(data))
                }}
              >
                جستجو
              </Button>
            </Col>
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {state.orgTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <>
              <div className='table-responsive'>
                {/* begin::Table */}
                {state.orgs.length < 1 ? (
                  'موردی یافت نشد'
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='min-w-100px'>{isFarmer ? 'کد غرفه' : 'کد مزرعه'}</th>
                        <th className='min-w-140px'>{isFarmer ? 'نام غرفه' : 'نام مزرعه'}</th>
                        <th className='min-w-140px'>استان</th>
                        <th className='min-w-140px'>شهرستان</th>
                        <th className='min-w-140px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.orgs.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item?.id}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.province?.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.township?.title}
                              </a>
                            </td>
                            <td className='text-end'>
                              <button
                                type='button'
                                onClick={(e) => {
                                  dispatch(handle_variables({CoopRequestModalOpen: true}))
                                  setToOrgId(item?.id)
                                }}
                                className='btn  btn-primary '
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                              >
                                درخواست همکاری
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                )}
                {/* end::Table */}
              </div>
              <div className='d-flex justify-content-center mt-10'>
                <Pagination
                  pageSize={state?.orgPagination.limit}
                  onChange={(e) => paginationOnChange(e)}
                  current={state?.orgPagination.page}
                  total={state?.orgPagination.total}
                />
                <Select
                  defaultValue={state.orgPagination.limit}
                  style={{width: 120, marginRight: '15px', height: '28px'}}
                  onChange={(e) => {
                    dispatch(handle_variables({orgPagination: {...state.orgPagination, limit: e}}))
                    dispatch(
                      getOrg({
                        page: 1,
                        pageSize: e,
                      })
                    )
                  }}
                  options={[
                    {value: 10, label: 10},
                    {value: 20, label: 20},
                    {value: 50, label: 50},
                    // { value: 'disabled', label: 'Disabled', disabled: true },
                  ]}
                />
              </div>
            </>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default CooperationList
