import {FC, useEffect, useState} from 'react'
// import {IconUserModel} from '../ProfileModels'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Button, OverlayTrigger, Tooltip, Modal, Row, Col} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useDispatch, useSelector} from 'react-redux'
// import {handle_variables} from '../Redux/flowerSlice'
// import {handle_variables as main_handle_variables} from '../../../redux/mainSlice'
// import FlowerDefinesModal from './flowerDefineModal'
import DeleteConfirmModal from '../../../../components/deleteConfrimModal'

import useSelection from 'antd/es/table/hooks/useSelection'
import {GridLoader} from 'react-spinners'
import {Select} from 'antd'
import Pagination from 'rc-pagination'
import {toast} from 'react-toastify'
import {getSellManagements, handle_variables, sellState} from '../../redux/sellSlice'
import PriceModal from './PriceModal'
import GradeModal from './GradeModal'
import {Link, useHistory} from 'react-router-dom'
import DefaultDropDown from '../../../../components/DefaultDropDown'
import {getGrades, gradeState} from '../../../grade/redux/gradeSlice'
import ChangeFlowersPriceModal from './ChangeFlowersPriceModal'
import {authState} from '../../../auth/redux/AuthSlice'
import {priceSlice} from '../../../../functions/priceSlice'

const SellManagment = () => {
  const dispatch = useDispatch()
  const state = useSelector(sellState)
  const authS = useSelector(authState)
  const gradeS = useSelector(gradeState)
  const history = useHistory()
  const [editMode, setEditMode] = useState(false)
  const [deleteComponentShow, setDeleteComponentShow] = useState(false)
  const [defaultValue, setDefaultValue] = useState(null)
  const [checkboxValues, setCheckboxValues] = useState<any[]>([])
  const [flowerTitleFilter, setFlowerTitleFilters] = useState('')
  const [gradeTitleFilter, setGradeTitleFilter] = useState('')

  const isExhibitor = authS?.user?.organization?.organizationType?.id == 2 ? true : false

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: 10,
    }
    dispatch(getSellManagements(data))

    if (authS?.user?.organization.organizationType?.id != 1) {
      dispatch(getGrades({page: 1, pageSize: 100}))
    }
  }, [])

  const roleShowHandler = (arr: any[]) => {
    let text = ''
    arr.map((item) => {
      text += item.title + ' - '
    })

    return text.slice(0, -2)
  }

  const paginationOnChange = (currentPage: number) => {
    dispatch(
      handle_variables({
        sellManagementPagination: {...state.sellManagementPagination, page: currentPage},
      })
    )
    dispatch(
      getSellManagements({page: currentPage, pageSize: state.sellManagementPagination.limit})
    )
  }

  const closeDeleteComponent = () => {
    setDeleteComponentShow(false)
  }
  const handleCheckboxChange = (id: number) => {
    const index = checkboxValues.indexOf(id)

    if (index !== -1) {
      // If id is found, remove it from the array
      const newArray = [...checkboxValues]
      newArray.splice(index, 1)
      setCheckboxValues(newArray)
    } else {
      // If id is not found, add it to the array
      setCheckboxValues([...checkboxValues, id])
    }

    setCheckboxValues((prevData: any) =>
      prevData.map((row: any) => (row.id === id ? {...row, isChecked: !row.isChecked} : row))
    )
  }

  const handleCheckAll = (checked: any) => {
    setCheckboxValues([])
    if (checked) {
      state.sellManagements.map((item: any) => {
        setCheckboxValues((prevData: any) => [...prevData, item.id])
      })
    }
  }

  return (
    <>
      {state.changePriceModalOpen ? (
        <PriceModal defaultValue={defaultValue} edit={editMode} />
      ) : null}

      {state.changeFlowersPriceModalOpen ? <ChangeFlowersPriceModal ids={checkboxValues} /> : null}

      {state.changeGradeModalOpen ? (
        <GradeModal defaultValue={defaultValue} edit={editMode} />
      ) : null}
      <div className={`card mb-5 mb-xl-8`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <Row className='w-100'>
            <Col lg={2}>
              <h3 className='card-title align-items-center'>
                <span className='card-label fw-bolder fs-3 mb-1'>مدیریت فروش</span>
              </h3>
            </Col>
            <Col lg={2}>
              <input
                className={'form-control form-control-lg form-control-solid'}
                onChange={(e) => setFlowerTitleFilters(e.target.value)}
                placeholder='نام گل'
              />
            </Col>
            {authS?.user?.organization.organizationType?.id != 1 ? (
              <Col lg={2}>
                <DefaultDropDown
                  name='flower'
                  onChange={(e: any) => {
                    console.log('eeeeeee', e)
                    setGradeTitleFilter(e.title)
                  }}
                  type='default'
                  placeholder='درجه گل'
                  options={gradeS.grades}
                />
              </Col>
            ) : null}

            <Col lg={1}>
              <Button
                // className='btn btn-primary'
                onClick={() => {
                  console.log('eeeeeeeeegradeTitleFilter', gradeTitleFilter)
                  const data = {
                    page: state?.sellManagementPagination?.page,
                    pageSize: state?.sellManagementPagination?.limit,
                    flowerTitle: flowerTitleFilter,
                    gradeTitle: gradeTitleFilter.toString(),
                  }
                  dispatch(getSellManagements(data))
                }}
              >
                جستجو
              </Button>
            </Col>
            {authS?.user?.organization.organizationType?.id != 1 ? (
              <Col lg={5} style={{textAlign: 'end'}}>
                <Button
                  disabled={checkboxValues?.length == 0 ? true : false}
                  onClick={() => {
                    dispatch(handle_variables({changeFlowersPriceModalOpen: true}))
                  }}
                  className='btn btn-primary'
                >
                  تغییر قیمت
                </Button>
              </Col>
            ) : null}
          </Row>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 mt-6'>
          {/* begin::Table container */}
          {state.sellManagementTableLoading ? (
            <GridLoader size={6} color={'white'} />
          ) : (
            <>
              <div className='table-responsive'>
                {/* begin::Table */}
                {state.sellManagements.length < 1 ? (
                  'موردی یافت نشد'
                ) : (
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {authS?.user?.organization.organizationType?.id != 1 ? (
                          <th className='w-25px'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                onChange={(e: any) => handleCheckAll(e.target.checked)}
                                value='1'
                                data-kt-check='true'
                                data-kt-check-target='.widget-13-check'
                              />
                            </div>
                          </th>
                        ) : null}

                        <th className='min-w-100px'>شماره گل</th>
                        <th className='min-w-140px'>نام</th>
                        <th className='min-w-140px'>
                          {authS?.user?.organization.organizationType?.id == 1 ? 'غرفه' : 'گلخانه'}
                        </th>
                        <th className='min-w-140px'>درجه</th>
                        <th className='min-w-140px'>تعداد</th>
                        <th className='min-w-140px'>قیمت</th>
                        <th className='min-w-140px'>حداقل قیمت</th>
                        <th className='min-w-140px'>وضعیت</th>
                        <th className='min-w-140px'></th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {state.sellManagements.map((item: any, ind: number) => {
                        return (
                          <tr key={ind}>
                            {authS?.user?.organization.organizationType?.id != 1 ? (
                              <td>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input widget-9-check'
                                    type='checkbox'
                                    checked={checkboxValues.includes(item.id) ? true : false}
                                    onChange={() => handleCheckboxChange(item.id)}
                                    // value='1'
                                  />
                                </div>
                              </td>
                            ) : null}

                            <td>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {item?.id}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.flower?.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {authS?.user?.organization.organizationType?.id == 1
                                  ? item?.toOrganization?.title
                                  : item?.fromOrganization?.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.grade?.title}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item?.amount}
                              </a>
                            </td>

                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                                onClick={() => {
                                  if (isExhibitor) {
                                    setDefaultValue(item)
                                    dispatch(handle_variables({changePriceModalOpen: true}))
                                  }
                                }}
                              >
                                {priceSlice({data: Number(item.price)})}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {priceSlice({data: Number(item.minimumPrice)})}
                              </a>
                            </td>
                            <td>
                              <a
                                href='#'
                                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                              >
                                {item.status ? (
                                  <span className='badge badge-light-success'>فعال</span>
                                ) : (
                                  <span className='badge badge-light-danger'>غیر فعال</span>
                                )}
                              </a>
                            </td>
                            <td className='text-end'>
                              <Link
                                to={`/sell/sale-history/${item.id}`}
                                className='btn btn-primary btn-bg-light btn-sm me-1'
                              >
                                تاریخچه
                              </Link>

                              {authS?.user?.organization.organizationType?.id != 1 ? (
                                <>
                                  <a
                                    onClick={() => {
                                      setDefaultValue(item)
                                      dispatch(handle_variables({changeGradeModalOpen: true}))
                                    }}
                                    href='#'
                                    className='btn btn-primary btn-bg-light btn-sm me-1'
                                  >
                                    درجه
                                  </a>
                                  <a
                                    onClick={() => {
                                      setDefaultValue(item)
                                      dispatch(handle_variables({changePriceModalOpen: true}))
                                    }}
                                    href='#'
                                    className='btn btn-primary btn-bg-light  btn-sm me-1'
                                  >
                                    قیمت
                                  </a>
                                  <button
                                    onClick={(e) => {
                                      // dispatch(handle_variables({changePriceModalOpen: true}))
                                      history.push(`/sell/sales-registration`)
                                    }}
                                    className='btn btn-success btn-bg-light  btn-sm'
                                  >
                                    فروش
                                  </button>
                                </>
                              ) : null}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                )}

                {/* end::Table */}
              </div>
              <div className='d-flex justify-content-center mt-10'>
                <Pagination
                  pageSize={state?.sellManagementPagination.limit}
                  onChange={(e) => paginationOnChange(e)}
                  current={state?.sellManagementPagination.page}
                  total={state?.sellManagementPagination.total}
                />
                <Select
                  defaultValue={state.sellManagementPagination.limit}
                  style={{width: 120, marginRight: '15px', height: '28px'}}
                  onChange={(e) => {
                    dispatch(
                      handle_variables({
                        sellManagementPagination: {...state.sellManagementPagination, limit: e},
                      })
                    )
                    dispatch(
                      getSellManagements({
                        page: 1,
                        pageSize: e,
                      })
                    )
                  }}
                  options={[
                    {value: 10, label: 10},
                    {value: 20, label: 20},
                    {value: 50, label: 50},
                  ]}
                />
              </div>
            </>
          )}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>

      <DeleteConfirmModal
        text={'آیا از پاک کردن این کاربر مطمعن هستید؟'}
        show={deleteComponentShow}
        close={() => closeDeleteComponent()}
        // onDelete={() => removeItem()}
      />
    </>
  )
}

export default SellManagment
