/**
 * API urls are defined here
 */

// GENERAL MODULE
export const GENERAL_GET_CONFIGS = '/configs'
export const MAIN_URL =
  process.env.NODE_ENV === 'production' ? 'https://api.raamino.com/' : 'http://171.22.26.68:13800/'
// process.env.NODE_ENV === 'production' ? 'https://api.raamino.com/' : 'http://192.168.0.100:13800/'
// process.env.NODE_ENV === 'production' ? 'https://api.raamino.com/' : 'http://localhost:13800/'
// process.env.NODE_ENV === 'production' ? 'https://api.raamino.com/' : 'http://172.16.16.39:13800/'
// process.env.NODE_ENV === 'production' ? 'https://api.raamino.com/' : 'http://192.168.1.181:13800/'
// export const MAIN_URL = process.env.NODE_ENV === 'production'?'/':'https://ariannlp2.bservice.ir/';
// export const MAIN_URL = 'https://api.raamino.com/'
// export const MAIN_URL = '/';
// export const WORKSPACE = 'g4bv2';
export const lang = 'fa'
export const API_URL = MAIN_URL + 'api/v1/' + lang + '/'
